<template>
  <div class="animated fadeIn">
    <b-form @submit.prevent="getSMStransactions">
      <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-2 my-1">
          {{ $t("message.year") }} :
          <br />
          <b-form-select v-model="timeSel" :options="language== 'th'? timeOptTH: timeOptEN" v-on:change="selectTime" />
        </div>

        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1">
          {{ $t('message.machineIdFarm') }}:
          <br />
          <list-select :list="machineData" :selected-item="selectedMachine" placeholder="Select Machine ID" option-value="machineId" option-text="name" @select="onSelect" required />
        </div>

        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-2 my-1" v-if="isAdmin(roleId)">
          transactionId:
          <br />
          <b-form-input v-model="searchData.transactionId"></b-form-input>
        </div>

        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-2 my-1">
          {{ $t('message.refNumber') }}:
          <br />
          <b-form-input v-model="searchData.refNumber"></b-form-input>
        </div>

        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
          {{ $t('message.date') }} :
          <br />
          <div class="table-responsive">
            <date-time-picker v-model="datetime"  ></date-time-picker>
          </div>
        </div>

        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-1 my-1 ">
          <br />
          <b-btn class="col-sm-12 col-md-12 " variant="primary" type="submit">
            <i class="fa fa-search"></i>
            &nbsp;{{ $t('message.search') }}
          </b-btn>
        </div>
      </div>
    </b-form>
    <br />
    <b-card>
      <div class="table-responsive">
        <table class="table-modal">
          <thead style="text-align-last: center">
            <th v-if="isAdmin(roleId)">transactionId</th>
            <th v-if="isAdmin(roleId)">transactionSMS</th>
            <th>{{ $t('message.machineIdFarm') }}</th>
            <th>{{ $t('message.refNumber') }}</th>
            <!-- <th>{{ $t('message.status') }}</th> -->
            <th>{{ $t('message.detail') }}</th>
            <th>{{ $t('message.statusSending') }}</th>
            <th style="text-align-last: right">{{ $t('message.creditUsed') }}</th>
            <th style="text-align-last: right" v-if="isAdmin(roleId)">{{ $t('message.creditBalance') }}</th>
            <th>{{ $t('message.createdAt') }}</th>
            <th>{{ $t('message.updatedAt') }}</th>
          </thead>
          <tbody>
            <template v-for="items in rowData">
              <tr style="text-align: right">
                <td v-if="isAdmin(roleId)">{{items.transactionId}}</td>
                <td v-if="isAdmin(roleId)">{{items.transactionSMS}}</td>
                <td>{{items.machineId}}</td>
                <td>{{items.refNumber}}</td>
                <!-- <td>
                  <template v-if="items.status === 1">
                    <span class="badge badge-success" style="font-size: 100%">{{ $t('message.success') }}</span>
                  </template>
                  <template v-else>
                    <span class="badge badge-danger" style="font-size: 100%">{{ $t('message.fail') }}</span>
                  </template>
                </td>-->
                <td>{{items.details}}</td>
                <td>
                  <template v-if="items.smsStatus === 'Delivered'">
                    <span class="badge badge-primary" style="font-size: 100%">{{ $t('message.customerReceive') }}</span>
                  </template>
                  <template v-else>
                    <span class="badge badge-danger" style="font-size: 100%">{{items.smsStatus}}</span>
                  </template>
                </td>
                <td style="text-align: right">{{items.usedcredit}}</td>
                <td style="text-align: right" v-if="isAdmin(roleId)">{{ Number(items.remaincredit).toLocaleString() }}</td>
                <td style="text-align: center"><label style="width: 140px;">{{items.createdAt | moment("YYYY-MM-DD HH:mm")}}</label></td>
                <td style="text-align: center"><label style="width: 140px;">{{items.updatedAt | moment("YYYY-MM-DD HH:mm")}}</label></td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <br />
      <b-pagination size="md" :total-rows="totalRows" v-model="pagination" :per-page="itemperPage" @input="pageChanged(pagination)"></b-pagination>
    </b-card>
  </div>
</template>

<script>
import webServices from '../../script'
import moment from 'moment'
// import DatePicker from '../../custom_modules/vue2-datepicker'
import DateTimePicker from '../../components/DateTimePicker/Custom.vue'
// import DatePicker from 'vue2-datepicker'
// import 'vue2-datepicker/index.css'

import { ListSelect } from '../../custom_modules/search-select'
export default {
  name: 'smsreport',
  components: {
    // DatePicker,
    DateTimePicker,
    ListSelect
  },
  data() {
    return {
      api: {},
      timeSel: 0,
      timeOptTH: [
        { value: 0, text: '2022 ถึง ปีปัจจุบัน' },
        { value: 1, text: '<2022'}
      ],
      timeOptEN: [
        { value: 0, text: '2022 To This Year' },
        { value: 1, text: '<2022'}
      ],
      language: window.localStorage.getItem("language"),
      roleId: window.localStorage.getItem('roleID'),
      datetime: '',
      // datetime: [moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'), moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')],
      searchData: {
        page: 1,
        rows: 30,
      },
      datetime: [moment().startOf('day').toDate(), moment().endOf('day').toDate()],
      rowData: [],
      totalRows: 1,
      pagination: 1,
      itemperPage: 30,
      machineData: [],
      selectedMachine: {}
    }
  },
  methods: {
    selectTime(value) {
      if (value > 0) {
        this.api.defaults.baseURL = '/old/' + this.axios.defaults.baseURL
      } else {
        this.api.defaults.baseURL = this.axios.defaults.baseURL
      }
    },

    showAlert_ChooseOneBranch() {
      if(window.localStorage.getItem("language") == 'th'){
        var title = "โปรดเลือกช่วงเวลาใหม่อีกครั้ง"
        var text = 'ไม่สามารถเลือกเดือนได้มากกว่า 12 เดือน'
        var confirmButton = "ปิด"
      }else{
        var title = "Please select a new time again"
        var text = "Cannot select more than 12 months."
        var confirmButton = "Close"
      }

      this.$swal({
        title: title,
        text: text,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: confirmButton
      })
    },

    monthDiff(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    },

    getSMStransactions() {
      this.$Progress.start()
      var timeEnd = moment(this.datetime[1]).format('HH:mm:ss')
      if (timeEnd === '00:00:00' || timeEnd === '12:00:00 AM') {
        this.datetime[1].setHours(23, 59, 59, 999)
      }
      this.searchData.from = moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss')
      this.searchData.to = moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss')

      console.log('this.date',   this.datetime)
      console.log('this.searchData',   this.searchData)

      var localStorageUser =  JSON.parse(window.localStorage.getItem("users") )
      var datetimeRequest = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

      this.searchData.userName = localStorageUser.userName
      this.searchData.userRole = localStorageUser.role
      this.searchData.userType = localStorageUser.type
      this.searchData.userConfiglanguage = localStorageUser.config.language
      this.searchData.datetimeRequest = datetimeRequest

      var dateStart = new Date(this.datetime[0])
      var dateEnd = new Date(this.datetime[1])
      console.log("monthDiff", this.monthDiff(dateStart, dateEnd))

      // if(this.monthDiff(dateStart, dateEnd) < 12){
        webServices
        .smsTransactions(this.searchData)
        .then(res => {
          this.$Progress.finish()
          console.log(res.data)
          this.rowData = res.data.rows
          this.totalRows = res.data.count
        })
        .catch(err => {
          this.$Progress.fail()
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
          console.log(err)
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          }
          this.$toast.error({
            title: 'ERROR',
            message: JSON.stringify(errorText)
          })
        })
      // }else{
      //   console.log("เลือกเดือนได้สูงสุด 12 เดือน")
      //   this.showAlert_ChooseOneBranch() // เลือกเดือนได้สูงสุด 12 เดือน
      // }
    },
    pageChanged(PageNum) {
      this.searchData.page = PageNum
      this.getSMStransactions()
    },
    onSelect(item) {
      this.selectedMachine = item
      this.searchData.machineId = item.machineId
    },
    isAdmin(role) {
      return webServices.isAdmin(role)
    },
    getMachine() {
      webServices
        .getMachineList(window.localStorage.getItem('userId'))
        .then(res => {
          this.machineData = res.data
          // console.log(this.machineData)
        })
        .catch(err => {
          console.log(err)
          console.log('error @MACHINE')
          this.$toast.error({
            title: 'ERROR',
            message: 'เกิดข้อผิดพลาด'
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    }
  },
  mounted() {
    this.getSMStransactions()
    this.getMachine()
  }
}
</script>